*{
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Raleway', sans-serif;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: rgb(50,50,50);
}
::-webkit-scrollbar-thumb {
    background: rgb(100,100,100);
    border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgb(200, 200, 200);
}  